import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { DataGridPro } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ButtonBase from '@mui/material/ButtonBase'
import Icon from '@mui/material/Icon'
import ReservationIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import Typography from '@mui/material/Typography'
import PeopleIcon from '@mui/icons-material/PeopleOutline'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import StarOutlinedIcon from '@mui/icons-material/StarOutline'
import NoteIcon from '@mui/icons-material/EditNote'
import Button from '@mui/material/Button'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import React from 'react'

export default function CardPage () {
  return (
    <>
      <Box sx={{
        position: 'fixed',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, -1em)',
        zIndex: 999,
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: 2,
        py: 2,
        px: 3,
      }}>
        <Button
          fullWidth
          variant={'text'}
          size={'large'}
          startIcon={<FiberNewIcon/>}
        >
          새 카드
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
          >
            <CardContent
              sx={{
                minHeight: '100px',
              }}
            >
              <DataGridPro columns={[]} rows={[]}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
